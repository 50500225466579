import React, { Component } from 'react'

export default class Error extends Component {
  render() {
    return (
      <div>
        <h1 className="text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center py-10">Error Page Not Found...</h1>
      </div>
    )
  }
}

