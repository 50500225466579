import React from 'react';
import { useState } from 'react'
import text from '../img/r3textlogo.png';
import artwork from '../img/r3Artwork.png';
import submit from '../img/submit.svg';
import { useNavigate, Link } from 'react-router-dom'

function App() {
  //Inital Form Values
  const initialValues = { twitter: "", invite: "", wallet: ""};
  const [formValues, setFormValues] = useState(initialValues);
  const navigate = useNavigate();
  
  const handleChange = (e) => {
    const {name,value} = e.target;
    setFormValues({...formValues, [name]: value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
      //Send Request to server
      const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues)
      };
      fetch(`https://r3-nft-nyc.eastus2.cloudapp.azure.com/api/nft-nyc`, requestOptions)
          .then(async response => {
            //added to WL 
            if(response.status===200) {
              console.log("Good")
              var resGood = await response.json();
              console.log(resGood.message);
              // return <Navigate to = '/success' replace={true}/>
              navigate('/success');
            }
            //Problem with code (invalid, used etc)
            if(response.status===400) {
              console.log("bad")
              var resBad = await response.json();
              console.log(resBad.message);
              alert(resBad.message);
            }
            //Rate Limit
            if(response.status===429) {
              console.log("Rate Limit")
              alert("You have been rate limited, please try again later!");
            }
          })
          .catch(error => {
              console.error('Error', error);
          });
        }

    return (
        <div className="w-full min-h-screen" style={{ backgroundColor: '#89A2A6' }}>
            <div className="relative w-32 ...">
                <div className="absolute left-0 top-0 h-16 w-32 ...">
                     <Link to="/"><img className="max-w-[8em] sm:max-w-full r3textlogo" src={text} alt="logo" /></Link>
                </div>
            </div>
             <div className="grid grid-cols-2 gap-4 ">
                <div className="flex items-center justify-center formSubmit">
                    <div className="grid grid-cols-1">
                        <label className="welcome">Welcome. Enter your details below</label>
                        <label className="notice">You will not be required to connect your wallet.</label>
                        <form onSubmit={handleSubmit}>
                            <div className="grid xl:gap-6">
                                <div className="relative z-0 w-full mb-6 group">
                                    <input type="text" name="invite" id="specialCode" value={formValues.invite} className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-white appearance-none dark:text-white dark:border-white dark:focus:border-amber-700 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Code" onChange={handleChange} required/>
                                </div>
                            </div>
                            <div className="grid xl:gap-6">
                                <div className="relative z-0 w-full mb-6 group">
                                    <input type="text" name="wallet" id="walletAddress" value={formValues.wallet} className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-white appearance-none dark:text-white dark:border-white dark:focus:border-amber-700 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Ethereum Wallet Address" onChange={handleChange} required />
                                </div>
                            </div>
                            <div className="grid xl:gap-6">
                                <div className="relative z-0 w-full mb-6 group">
                                    <input type="text" name="twitter" id="twitterHandle" value={formValues.twitter} className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-white appearance-none dark:text-white dark:border-white dark:focus:border-amber-700 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Twitter (ex. @r3cruits)" onChange={handleChange} required />
                                </div>
                            </div>
                            <button className="buttonMainProps">
                              <img src={submit} alt="submit"/>
                            </button>
                        </form>
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <img className="artworkimg" src={artwork} alt="artwork" />
                </div>
            </div>

            
        </div>
    );
}

export default App;
