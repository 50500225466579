import logo from '../img/r3Logo.svg';
import text from '../img/r3Text.svg';
import '../App.scss';


function App() {
  return (  
    <div className="mt-[15em] md:mt-96 xl:mt-[13em]  1xl:mt-[15em] 2xl:mt-[20em]">
      <div className="mx-[3em] md:mx-96 xl:mx-96  1xl:mx-[35em] 2xl:mx-[50em] logoAnimate">
       {/* Test placeholder can be change in the future */}
        <figure><img className="min-w-full logo-img" src={logo} alt="logo"/></figure>
        <div className="grid mt-[1em] sm:mt-[2em] place-content-center">
          <div>
            <a href="https://twitter.com/R3cruits" target="_blank" rel="noreferrer noopener"><img className="max-w-[8em] sm:max-w-full"src={text} alt="logo"/></a>
          </div>
        </div>
      </div>
    </div>
    
    
  );
}

export default App;
