import React from 'react';
import text from '../img/r3textlogo.png';
import artwork from '../img/r3Artwork.png';
import tweet from '../img/tweet.svg';
import { Link } from 'react-router-dom'

function App() {
    return (
        <div className="w-full min-h-screen fade-in" style={{ backgroundColor: '#FA7D45' }}>
            <div className="relative w-32 ...">
                <div className="absolute left-0 top-0 h-16 w-32 ...">
                     <Link to="/"><img className="max-w-[8em] sm:max-w-full r3textlogo" src={text} alt="logo" /></Link>
                </div>
            </div>
             <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center justify-center formSubmit">
                    <div className="grid grid-cols-1">
                        <label className="congrats">
                          Congrats on being r3cruited.
                        </label>
                        <label className="walletNotice">
                          Your wallet has been recorded.
                        </label>
                        <a href="https://twitter.com/intent/tweet?text=Just%20got%20%23R3cruited%20at%20NFT%20NYC!%20%40R3cruits%20%23NFTNYC">
                          <button className="mt-[2em] buttonAnimation">
                            <img src={tweet} alt="tweet"/>
                          </button>
                        </a>
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <img className="successartworkimg" src={artwork} alt="artwork"/>
                </div>
            </div>
        </div>
    );
}

export default App;
