import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//WebPages
import LandingPage from './pages/LandingPage';
import Error from './pages/Error';
import Nycnft from './pages/Nycnft';
import NftnycSuccess from './pages/NftnycSuccess';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path='/nft-nyc' element={<Nycnft />} />
        <Route path='/success' element={<NftnycSuccess />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>

  );
}

export default App;
